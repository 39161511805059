import * as React from 'react';

// components
import Gallery from '../components/Gallery';
import Hero from '../components/Hero';
import Layout from '../components/Layout';

// data
import portfolio from '../images/portfolio';

// styles
import '../styles/index.scss';

export default function IndexPage() {
  return (
    <Layout path="/" title="Welcome" >
      <Hero />

      <Gallery images={portfolio.views.home} />
    </Layout>
  );
}
