import React from 'react';

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero--content" >
        <div className="hero--name">Gwynevere Bonacci</div>
        <div className="hero--about">Graphic Designer and Illustrator</div>
      </div>
    </div>
  )
}